@import url(https://fonts.googleapis.com/css2?family=Ubuntu:wght@400;500;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/************ genaral ***********/
/**********
font-family:'Montserrat', sans-serif;
font-family:'Spartan';
***********/
body{font-family:'Montserrat', sans-serif;color:#000;font-weight:400;font-size:17px;line-height:25px;overflow-x:hidden;/*overflow-y:scroll;scrollbar-color:#FAEFDC #E7AF51;scrollbar-width:thin;scrollbar-height:thin;*/}
/*.scroller{overflow-y:scroll;scrollbar-color:#FAEFDC #E7AF51;scrollbar-width:thin;scrollbar-height:thin;}
.scroller{overflow-x:scroll;scrollbar-color:#FAEFDC #E7AF51;scrollbar-width:thin;scrollbar-height:thin;}*/
img{display:block;width:auto;height:auto;max-width:100%;}
::-webkit-input-placeholder{color:#000;opacity:1;font-weight:400;}
:-ms-input-placeholder{color:#000;opacity:1;font-weight:400;}
::placeholder{color:#000;opacity:1;font-weight:400;}    
:placeholder{color:#000;opacity:1;font-weight:400;}
a,a:hover,a:focus,a:active,a:visited{text-decoration:none;box-shadow:none;outline:none;border:none;text-shadow:none;}
a{color:#000;}
a:hover,a:focus,a:active,a:visited{color:#F23F11;}
.btn{display:inline-block;background:#F58634;border:none;font-weight:400;color:#fff !important;transition:all .2s;-webkit-transition:all .2s;-moz-transition:all .2s;-ms-transition:all .2s;-o-transition:all .2s;padding:13px 33px 15px 33px;font-size:17px;line-height:22px;position:relative;outline:0;text-align:center;text-transform:none;cursor:pointer;text-decoration:none;width:auto;border-radius:10px;box-sizing:border-box;outline:none;box-shadow:none;}
.btn:focus, .btn:hover{background:#0C97B9;border:none;color:#fff;text-decoration:none;outline:none;box-shadow:none;}

.btn-with-shadow{display:inline-block;background:#E7AF51;border:none;font-weight:500;color:#000;transition:all .2s;-webkit-transition:all .2s;-moz-transition:all .2s;-ms-transition:all .2s;-o-transition:all .2s;padding:14px 20px 14px 30px;line-height:22px;font-size:18px;position:relative;outline:0;text-align:center;text-transform:none;cursor:pointer;text-decoration:none;width:auto;border-radius:50px;box-sizing:border-box;line-height:22px;outline:none;box-shadow:0px 3px 6px 0px rgba(0,0,0,0.5);-webkit-box-shadow:0px 3px 6px 0px rgba(0,0,0,0.5);-o-box-shadow:0px 3px 6px 0px rgba(0,0,0,0.5);-ms-box-shadow:0px 3px 6px 0px rgba(0,0,0,0.5);-moz-box-shadow:0px 3px 6px 0px rgba(0,0,0,0.5);font-weight:500;}
.btn-with-shadow:focus, .btn-with-shadow:hover{background:#E7AF51;border:none;color:#000;text-decoration:none;outline:none;box-shadow:0px 3px 12px 0px rgba(0,0,0,0.5);-webkit-box-shadow:0px 3px 12px 0px rgba(0,0,0,0.5);-o-box-shadow:0px 3px 12px 0px rgba(0,0,0,0.5);-ms-box-shadow:0px 3px 12px 0px rgba(0,0,0,0.5);-moz-box-shadow:0px 3px 12px 0px rgba(0,0,0,0.5);}
.btn-with-shadow.with-image{font-size:0;line-height:0;}
.btn-with-shadow.with-image .btn-text{line-height:22px;font-size:18px;font-weight:500;display:inline-block;position:relative;vertical-align:middle;padding-right:30px;}
.btn-with-shadow.with-image .btn-image{display:inline-block;position:relative;vertical-align:middle;}
.btn:hover i{color:#c4192c}
.btn:hover svg{color:#c4192c;}
h1,h2,h3,h4,h5,h6{color:#000;font-weight:bold;margin:0;font-family:'Ubuntu';}
h1{font-size:70px;line-height:85px;}
h2{font-size:45px;line-height:55px;}
h3{font-size:40px;line-height:50px;}
h4{font-size:35px;line-height:40px;}
h5{font-size:24px;line-height:35px;}
h6{font-size:20px;line-height:30px;}
*{box-sizing:border-box;-webkit-box-sizing:border-box;-moz-box-sizing:border-box;-o-box-sizing:border-box;-ms-box-sizing:border-box;}
p{margin:0 0 20px 0;}
p:last-child,p:last-of-type{margin:0;}
/* width */
/*::-webkit-scrollbar{width:8px;}*/
/* Track */
/*::-webkit-scrollbar-track{background:#FAEFDC;}*/
/* Handle */
/*::-webkit-scrollbar-thumb{background:#E7AF51;}*/
/* Handle on hover */
/*::-webkit-scrollbar-thumb:hover{background:#E7AF51;}*/
/* Works on Firefox */
/**{scrollbar-width:thin;scrollbar-height:thin;scrollbar-color:#E7AF51 #FFD7A8;}*/
/* Works on Chrome, Edge, and Safari */
/**::-webkit-scrollbar{width:8px;}
*::-webkit-scrollbar{height:8px;}
*::-webkit-scrollbar-track{background:#FFD7A8;}
*::-webkit-scrollbar-thumb{background-color:#E7AF51;border-radius:8px;border:3px solid #E7AF51;}*/
.font-weight-semibold{font-weight:600 !important;}
.font-weight-medium{font-weight:500 !important;}
/*header*/
header{padding:10px 0;}
.fusion-mobile-menu-icons{display:none;}
.site-main-menu ul.site-main-menu-ul{list-style-type:none;margin:0;padding:0;float:none;clear:both;display:block;text-align:right;font-size:0;line-height:0;}
.site-main-menu ul.site-main-menu-ul li{list-style-type:none;margin:0;padding:10px 20px;float:none;clear:both;display:inline-block;font-size:0;line-height:0;position:relative;vertical-align:middle;text-align:center;}
.site-main-menu ul.site-main-menu-ul li:first-child{padding-left:0;}
.site-main-menu ul.site-main-menu-ul li:last-child{padding-right:0;}
.site-main-menu ul.site-main-menu-ul li ul{display:none;position:absolute;left:0;top:100%;width:315px;height:auto;padding:0;border-top:1px solid #CCCCCC;border-left:1px solid #CCCCCC;background-color:#fff;}
.site-main-menu ul.site-main-menu-ul > li > a{display:inline-block;font-size:0;line-height:0;position:relative;vertical-align:middle;text-decoration:none;}
.site-main-menu ul.site-main-menu-ul > li > a > span{font-size:17px;line-height:20px;display:block;color:#fff;}
.site-main-menu ul.site-main-menu-ul > li > span.fusion-caret{display:inline-block;position:relative;vertical-align:middle;font-size:14px;line-height:14px;color:#ffffff;padding-top:4px;margin-left:11px;font-weight:100;cursor:pointer;}
.site-main-menu{text-align:right;}
.site-main-menu ul.site-main-menu-ul > li:hover > ul{display:block;}

.site-main-menu ul.site-main-menu-ul li.open_menu{}
.site-main-menu ul.site-main-menu-ul > li.open_menu > span.fusion-caret{}
.site-main-menu ul.site-main-menu-ul > li.open_menu > span.fusion-caret svg{-webkit-transform:rotate(180deg);transform:rotate(180deg);}
.site-main-menu ul.site-main-menu-ul > li.open_menu > ul{display:block;}
.site-main-menu ul.site-main-menu-ul > li > ul > li{padding:0px 30px 0px 15px;border-right:1px solid #CCCCCC;border-bottom:1px solid #CCCCCC;display:block;text-align:left;}
.site-main-menu ul.site-main-menu-ul > li > ul > li:first-child{padding-left:15px;}
.site-main-menu ul.site-main-menu-ul > li > ul > li:last-child{padding-right:30px;}
.site-main-menu ul.site-main-menu-ul > li > ul > li > a{display:block;font-size:0;line-height:0;color:#000000;text-decoration:none;text-align:left;padding:10px 0;} 
.site-main-menu ul.site-main-menu-ul > li > ul > li > a > span{display:block;font-size:17px;line-height:20px;color:#000000;} 

.site-main-menu ul.site-main-menu-ul > li > ul > li > span.fusion-caret{display:block;position:absolute;font-size:14px;line-height:14px;color:#000000;cursor:pointer;right:0;top:5px;-webkit-transform:rotate(-90deg);transform:rotate(-90deg);padding:8px;}
.site-main-menu ul.site-main-menu-ul > li > ul > li > ul{position:absolute;left:100%;top:0;width:315px;height:auto;padding:0;border-top:1px solid #CCCCCC;border-left:1px solid #CCCCCC;background-color:#fff;display:none;}
.site-main-menu ul.site-main-menu-ul > li > ul > li:hover > ul{display:block;}
.site-main-menu ul.site-main-menu-ul > li > ul > li > ul > li{}
.site-main-menu ul.site-main-menu-ul > li > ul > li > ul > li{padding:0px 15px 0px 15px;border-right:1px solid #CCCCCC;border-bottom:1px solid #CCCCCC;display:block;text-align:left;}
.site-main-menu ul.site-main-menu-ul > li > ul > li > ul > li:first-child{padding-left:15px;}
.site-main-menu ul.site-main-menu-ul > li > ul > li > ul > li:last-child{padding-right:15px;}
.site-main-menu ul.site-main-menu-ul > li > ul > li > ul > li > a{display:block;font-size:0;line-height:0;color:#000000;text-decoration:none;text-align:left;} 
.site-main-menu ul.site-main-menu-ul > li > ul > li > ul > li > a > span{display:block;font-size:17px;line-height:20px;color:#000000;padding:10px 0;} 

/*footer*/
footer{font-size:0;line-height:0;display:block;width:100%;background-color:#F58634;}
footer .footer-widget-container{padding:37px 0;background-color:#F58634;}
footer .footer-copyright-container{background-color:#0C97B9;padding:15px 0;}
footer .footer-copyright-container .footer-copyright{font-size:15px;line-height:20px;padding:0 0 0 30px;color:#fff;background-image:url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAABHNCSVQICAgIfAhkiAAAAbRJREFUOE+1lOFRwzAMhZMJYAPCBMAEsAFkAtoJoBMAE1AmoJmAMgEwAe0G6QYwQXhfkHKOcA7+4DtdLvLzs54kqywmVtd1M22dyo7NQG7M1mVZPueOltEpogv57mXV1GXmb/VdiHid4kaEIltq8yoQ7fTPYRaXHIT9pUgX7hsIM2SNQICROSzhSMG17DJxPwiHr+gJTeaTAT45IMAqRDL6tRyjaM82auQ7IZJcytzJdAiJpICoWERLNOAJZKbPo+218h+WwdnICSiCY7C3wt0ZDiUuv4YwdZyQM8vTu7HQHkhjncluZOnFRO/YBkJkHMl2IqvCrUOyPUQuyxSKFJCyDYSdgd8EJALkOqCPOOqN/8K/yscjKP6F0CX3VbII6f5zWU5y5VVO0uCKtlNFQfqLHaAgNPmHjGrSwLzluV3+oyi8XW/qv7ZNinM18Ne/NTaREhFR7MtIPs+Rb+zVvktyTw9pTJGV5yj3tQfBVOKi7+j86dltcdJASFFyw4HmJlW+xsPBvRPjq9U+xqrM0qBHnTA1YIk2zr2onDnJVJoesOkJG2nIoiA8TdZWRgpomxGRn/0C8+j0erD+MREAAAAASUVORK5CYII=);background-repeat:no-repeat;background-position:left center;background-size:auto;}
footer .footer-copyright-container .footer-social{text-align:right;}
footer .footer-copyright-container .footer-social ul{list-style-type:none;margin:0;padding:0;float:none;clear:both;display:block;text-align:right;font-size:0;line-height:0;}
footer .footer-copyright-container .footer-social ul li{list-style-type:none;margin:0;padding:0 10px;float:none;clear:both;display:inline-block;font-size:0;line-height:0;position:relative;vertical-align:middle;text-align:center;}
footer .footer-copyright-container .footer-social ul li a{display:block;text-decoration:none;outline:none;box-shadow:none;text-shadow:none;}
footer .footer-copyright-container .footer-social ul li a:hover{opacity:0.75;text-decoration:none;outline:none;box-shadow:none;text-shadow:none;}
footer .footer-copyright-container .footer-social ul li a img{display:block;}
.footer-widget-container .footer-widget-column{}
.footer-widget-container .footer-widget-column > div.footer-widget-wrapper:first-of-type,
.footer-widget-container .footer-widget-column > div.footer-widget-wrapper:first-child{padding:0;margin:0;}
.footer-widget-container .footer-widget-column > div.footer-widget-wrapper{padding:30px 0 0 0;margin:0;}
.footer-widget-container .footer-widget-column .footer-widget-wrapper .footer-widget-logo-inner{text-align:left;}
.footer-widget-container .footer-widget-column .footer-widget-wrapper .footer-widget-logo-inner a{text-align:left;display:inline-block;position:relative;vertical-align:middle;}
.footer-widget-container .footer-widget-column .footer-widget-wrapper .footer-widget-logo-inner a img{display:block;}
.footer-widget-container .footer-widget-column .footer-widget-wrapper .footer-widget-text-inner{font-size:12.5px;line-height:17px;color:#fff;}
.footer-widget-container .footer-widget-column .footer-widget-wrapper .footer-widget-title{padding:0 0 30px 0;}
.footer-widget-container .footer-widget-column .footer-widget-wrapper .footer-widget-title h5{color:#fff;margin:0;}
.footer-widget-container .footer-widget-column .footer-widget-wrapper.footer-widget-text .footer-widget-content{}
.footer-widget-container .footer-widget-column .footer-widget-wrapper.footer-widget-text .footer-widget-content ul{list-style-type:none;margin:0;padding:0;float:none;clear:both;display:block;text-align:right;font-size:0;line-height:0;}
.footer-widget-container .footer-widget-column .footer-widget-wrapper.footer-widget-text .footer-widget-content ul li{list-style-type:none;margin:0 0 15px 0;padding:0 0 0 27px;float:none;clear:both;display:block;font-size:12px;line-height:20px;color:#fff;position:relative;vertical-align:middle;text-align:left;background-repeat:no-repeat;background-size:auto;}
.footer-widget-container .footer-widget-column .footer-widget-wrapper.footer-widget-text .footer-widget-content ul li:last-child{margin:0;}
.footer-widget-container .footer-widget-column .footer-widget-wrapper.footer-widget-text .footer-widget-content ul li a{font-size:12px;line-height:20px;color:#fff;text-decoration:none;outline:none;box-shadow:none;text-shadow:none;}
.footer-widget-container .footer-widget-column .footer-widget-wrapper.footer-widget-text .footer-widget-content ul li a:hover{color:#000;text-decoration:none;outline:none;box-shadow:none;text-shadow:none;}
.footer-widget-container .footer-widget-column .footer-widget-wrapper.footer-widget-text .footer-widget-content ul li.address{background-image:url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAUCAYAAABSx2cSAAAABHNCSVQICAgIfAhkiAAAAVZJREFUOE+Nk2uRwkAQhA8HJyESkBAHnAMigXMQByBhUXA4IDjgHEQCEuhva5oaNvnBVE3tY6Yfu5tsvpYxaGun7JX/yq3yqrwoz7l9kxad5n/Km7Io76kGAaQQ/ihnagYDnKKQQa0vSHAAyWwwAJgNhGyvZEQFu1VNAUFhBAyIjUMUWTM/BQACrwERjBNgbIxKVGm0rUc0MnzTrPR5ERsB00SRgGQO5oSt0yH6cEQ8AMPYx0YJIHtt0IOyj1cvDKUuOsdYQ9LGoA0cWrmCUaFgkk/OjFgBbBtmhIi9YyL8DUU7ol7PjBXftK3CDAkjjgAxOpjXdyYoTjGmntUppD3kBvsd2czv26Lf+vKPwTmw6TtYk/VXV+8ng1ljnQI33gZ2bbnWWrBt0cQlOvgci7JXvo7Vgmmm0W89a96FI76uTLhQzkrYxwGK3MMbcM12cvpysFB00xPkZVsF6JexOAAAAABJRU5ErkJggg==);background-position:left 3px top 0px;}
.footer-widget-container .footer-widget-column .footer-widget-wrapper.footer-widget-text .footer-widget-content ul li.email{background-image:url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAABHNCSVQICAgIfAhkiAAAAX5JREFUOE+llAtRA0EQRIkDcHASQAHngDggOAgODgXEAcEBEg4FIOFQAA6gX2o6DHObIgVbNbXfeds991mcfLcPDV/T3MOtBlNMztUvG2dYP2V9kTY/y7yRd3Bpn3sMsBPmSoGD56Q2048GbpSFnTGye/UvituidQbE/3uxPEQSUBS+KVBLcH5dynWGCyyz+aS4LEBUoYgHxb4Lzxp7K8UUUBRSjiVAEtjEimtKEgEIJez7YtaHgAKmAbxQbAFQbBKvC5DXA7sE446EctGUgI+cA8gtHHxIQM6hHJvsUV8UoPJecRPg4O0UsrYysGcSh+hpnmO5U+CE3pcwp3Hh6D4DM8RQrA4K6nunmALiLsNYGyuwBWUNVcCsqirzBU3gIWgWV5X9CkQRT57a2H622WtCOXhwuTUVGkYCY5INtTK/7BU6A2aYbwcG1F8DUFrr7A9g64DtZIXZYs3ZA3nXqFmrLqVMs2mGbvwt8wX8BWa6obu/DXZQyOf1n8bva/0FrhF7dSsJHAYAAAAASUVORK5CYII=);background-position:left top;}
.footer-widget-container .footer-widget-column .footer-widget-wrapper.footer-widget-text .footer-widget-content ul li.tel{background-image:url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAABHNCSVQICAgIfAhkiAAAAWBJREFUOE+dlA1RAzEQRqkCqALqACQcDooCrg7AwTmAKiASigIOBVAF1AE4gO/d7ML2Jj22ZOabTa7Ztz9JOjv5HUtN7215J7sJv6WnM9vZyj5Ija172VuppEm2ESAQssG+2fdL2X9BAQLpKiU6lFYATw2AX5KXPnai7IWVnwZ+mhM2jjMtnqW1VFI0y4z+oejksK2+t1kY+ygVhxvpKjh2mjemY3gDkGx2EoeAZfiBLDQft2IygB8GGeEcy+NenkvXx6ToQM8SoL8QvvXSq7TKQuN14b4Vy9TLnIIS/EJ6ssBDzPH9o0w2xQMCStbcV8onGIHpM7aTfp5p7UJT5nulTIKRFS2YS43B955pDThVJs60Bng8fYe2h56cl/lhmWauDtDNIeDQYMuEjCiVVkyNFBAAvSrWO/54dxUqMN796q8M3ZcWcJIIR079xeBU8CgRrGSBEQwAkfmpgTvZnk3fDbZQdXv9hmEAAAAASUVORK5CYII=);background-position:left top;}
/*homepage*/
.main-third-section{padding:40px 0 50px 0;}
.main-third-section .third-section-inner{background-color:rgba(181,181,181,0.05);padding:50px 15px;font-size:0;line-height:0;margin:0;border-radius:10px;overflow:hidden;}
.main-third-section .third-section-inner .third-section-inner-image{text-align:center;padding:0;margin:0 0 30px 0;}
.main-third-section .third-section-inner .third-section-inner-image img{display:block;margin:0 auto;}
.main-third-section .third-section-inner .third-section-inner-title{padding:0 0 17px 0;text-align:center;}
.main-third-section .third-section-inner .third-section-inner-title h5{line-height:30px;margin:0;text-align:center;}
.main-third-section .third-section-inner .third-section-inner-text{margin:0;padding:0;text-align:center;font-size:17px;line-height:25px;}
.main-third-section .third-section-inner .third-section-inner-text p{margin:0;}
.main-fourth-section{padding:50px 0 20px 0;}
.main-fourth-section-inner-left{}
.main-fourth-section-inner-left .main-fourth-section-inner-left-single-column{}
.main-fourth-section-inner-left .main-fourth-section-inner-left-single-column.left{}
.main-fourth-section-inner-left .main-fourth-section-inner-left-single-column.right{padding-top:54px;}
.main-fourth-section-inner-left .main-fourth-section-inner-left-single-column > div.main-fourth-section-inner-left-single{padding:0 0 30px 0;}

.main-fourth-section-inner-left-single .main-fourth-section-inner-left-single-image{display:block;font-size:0;line-height:0;margin:0;padding:0;border-radius:15px 15px 0 0;overflow:hidden;}
.main-fourth-section-inner-left-single .main-fourth-section-inner-left-single-image img{display:block;font-size:0;line-height:0;margin:0;padding:0;width:100%;max-width:100%;}
.main-fourth-section-inner-left-single .main-fourth-section-inner-left-single-title{border-radius:0 0 15px 15px;background-color:#fff;padding:15px 0 10px 0;box-shadow:0px 4px 4px 0px rgba(0,0,0,0.1);text-align: center;}
.main-fourth-section-inner-left-single .main-fourth-section-inner-left-single-title h5{font-weight:500;font-style:normal;text-align:center;font-size:23px;line-height:33px;}
.main-fourth-section-inner-right{}
.main-fourth-section-inner-right h5{color:#F58634;text-transform:uppercase;margin:0 0 16px 0;}
.main-fourth-section-inner-right h4{margin:0 0 10px 0;}
.main-fourth-section-inner-right .horizontal-row{width:70px;height:3px;background-color:#F58634;clear:both;overflow:hidden;margin:0 0 50px 0;padding:0;}
.main-fourth-section-inner-right .fourth-section-right-text{line-height:30px;padding:0 0 30px 0;}
.main-fourth-section-inner-right .fourth-section-right-text p{margin:0 0 30px 0;}
.main-fourth-section-inner-right .fourth-section-right-text p:last-child{margin:0 0 0 0;}
.main-fourth-section-inner-right .fourth-section-right-button{padding:0;text-align:left;}
.main-fourth-section-inner-right .fourth-section-right-button a{}
.main-fifth-section{padding:50px 0 50px 0;}
.main-fifth-section-title{font-size:0;line-height:0;padding:0 0 50px 0;}
.main-fifth-section-title h4{text-align:center;margin:0;}
.main-fifth-section .main-fifth-section-form{font-size:0;line-height:0;}
.main-fifth-section .main-fifth-section-form .subscribe_blog_form{font-size:0;line-height:0;box-shadow:0px 3px 6px 0px rgba(0,0,0,0.16);border-radius:10px;clear:both;overflow:hidden;}
.main-fifth-section .main-fifth-section-form .subscribe_blog_form .subscribe_blog_form_inn{font-size:0;line-height:0;display:block;position:relative;vertical-align:middle;padding:15px 15px 15px 50px;border-radius:10px;box-shadow:inset 0px 3px 6px 0px rgba(0,0,0,0.16);clear:both;overflow:hidden;background-image:url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAABHNCSVQICAgIfAhkiAAAAkBJREFUOE+NlEF22jAQhmdkP/rALMgNOIJSsy83iG9QcgN3GdjQBWRZ36DuDXwEs4fiI6QnCAvc9BFb05GNQBhIwkZISJ/+/58RCPvPdu5vADAz88NIKnaEeNJzIiUViOBsD5Dsjlc9vY7mx3w+IG+8PMzPD11fsc++C3x5lH1Szp1C2DhYLtoPWaXW/nwYuJ35ESBKJEgrywhDHtbd8fLbm0Cayt7flvtsW85ng6k+1Hktorzl3DmIf0ql+giiT0C97mQV2nF1dsUNTrMNaljechP2/sUGbueDlJUM9VgrJEmIPbPmYDEy9rVlAlh4uyLAfO5nROUI0V0b4MvjYKgUDAmKBNEJvfFqZC7WQK1eCEjbD8sqigpIxS3vjVG3C6tLuOBfbWCpKHBEGSnlRN5kFejilOTGNdBPhChDWyE7+MUqAwYOUuBeQxQ/TzJk5axMsoOYAJ9ZyC1ydnzoB19+r8F2htyj94BiVAGrXGaf2TYOtT290cw7uzL898mVAAW3i9vXzd3esQAuQG2XLyRKu5PfcZW3AdoQA+XN+lVMgWDNFr83e9CGVeebwEvQek1K7xWejKqmMmP9IvAa9LSRjzbt9TeAUiI4Cb/01Ng/FsCPOQJuqTLoTrKTP5MrlmuYPgAgpF0okxmAysweG3qhKEeY2Wiqzar4NdBCV9Pk2oSeAHXwl241merRwA5FaJw5AIGK8BrMDv3Sd1sIoBtVb5nt8As4D/k9WFMpF3GD+4zC/fP6KONsHz/LG4EY/gfVf7m6xvOdrQAAAABJRU5ErkJggg==);background-repeat:no-repeat;background-position:left 20px center;}
.main-fifth-section .main-fifth-section-form .subscribe_blog_form .subscribe_blog_form_inn .subscribe_blog_for_input{display:block;float:left;width:calc(100% - 188px);}
.main-fifth-section .main-fifth-section-form .subscribe_blog_form .subscribe_blog_form_inn .subscribe_blog_for_input input.emailadd-input{display:block;width:100%;font-size:17px;line-height:50px;font-weight:normal;padding:0 10px;color:#000;border:none;outline:none;}
.main-fifth-section .main-fifth-section-form .subscribe_blog_form .subscribe_blog_form_inn .subscribe_blog_for_input input.emailadd-input
.main-fifth-section .main-fifth-section-form .subscribe_blog_form .subscribe_blog_form_inn .subscribe_blog_for_btn{display:block;float:left;width:188px;}
.main-fifth-section .main-fifth-section-form .subscribe_blog_form .subscribe_blog_form_inn .subscribe_blog_for_btn input[type=submit]{border-radius:10px;font-size:17px;line-height:50px;background-color:#F58634;border:none;outline:none;color:#fff;width:188px;max-width:100%;padding:0;box-shadow:none;}
.main-fifth-section .main-fifth-section-form .subscribe_blog_form .subscribe_blog_form_inn .subscribe_blog_for_btn input[type=submit]:hover,
.main-fifth-section .main-fifth-section-form .subscribe_blog_form .subscribe_blog_form_inn .subscribe_blog_for_btn input[type=submit]:focus{box-shadow:none;background-color:#0C97B9;border:none;outline:none;color:#fff;border-radius:10px;}
.main_sixth_section_slider_inner{text-align:center;display:block;font-size:0;line-height:0;margin:0;padding:0;}
.main_sixth_section_slider_inner img{display:block;margin:0 auto;padding:0;font-size:0;line-height:0;filter:grayscale(100%);-webkit-filter:grayscale(100%);}
.main-sixth-section{padding:30px 0 80px 0;}
.main_sixth_section_slider_inner img:hover{filter:grayscale(0%);-webkit-filter:grayscale(0%);}
.main-second-section{padding:40px 0 50px 0;}
.main-second-section .main-second-section-title{text-align:center;display:block;font-size:0;line-height:0;margin:0;padding:0 0 50px 0;}
.main-second-section-title .main-second-section-title-inner{text-align:center;display:block;font-size:0;line-height:0;margin:0;padding:0 0 7px 0;position:relative;}
.main-second-section-title .main-second-section-title-inner:after{content:"";position:absolute;left:50%;bottom:0;height:3px;width:70px;margin:0 0 0 -35px;padding:0;font-size:0;line-height:0;background-color:#F58634;}
.main-second-section .main-second-section-content{}
.main-second-section-content .main-second-section-content-inner{}
.main-second-section-content-inner .main_second_section_content_slide_inner{display:block;padding:0 15px 50px 15px;margin:0;font-size:0;line-height:0;overflow:hidden;}
.main-second-section-content-inner .main_second_section_content_slide_inner a{display:block;padding:0;margin:0;font-size:0;line-height:0;box-shadow:0px 3px 3px 0px rgba(0,0,0,0.1);border-radius:10px;text-decoration:none;outline:none;}
.main-second-section-content-inner .main_second_section_content_slide_inner a:hover{text-decoration:none;outline:none;}
.main_second_section_content_slide_inner .main_second_section_content_slide_inner_title_info{border-radius:0 0 10px 10px;overflow:hidden;display:block;padding:20px;margin:0;font-size:0;line-height:0;}
.main_second_section_content_slide_inner .main_second_section_content_slide_inner_image{border-radius:10px 10px 0 0;overflow:hidden;display:block;padding:0;margin:0;font-size:0;line-height:0;}
.main_second_section_content_slide_inner .main_second_section_content_slide_inner_image img{display:block;padding:0;margin:0;font-size:0;line-height:0;width:100%;max-width:100%;height:auto;}
.main_second_section_content_slide_inner .main_second_section_content_slide_inner_title_info .main_second_section_content_slide_inner_title{text-align:left;font-weight:500;font-style:normal;margin:0;padding:0 0 7px 0;}
.main_second_section_content_slide_inner .main_second_section_content_slide_inner_title_info .main_second_section_content_slide_inner_title h5{text-align:left;font-weight:500;font-style:normal;margin:0;padding:0;}
.main_second_section_content_slide_inner .main_second_section_content_slide_inner_title_info .main_second_section_content_slide_inner_info{text-align:left;display:block;position:relative;vertical-align:middle;font-size:17px;line-height:20px;color:#000;}
.main_second_section_content_slide_inner .main_second_section_content_slide_inner_title_info .main_second_section_content_slide_inner_info span{display:inline-block;position:relative;vertical-align:middle;color:#000;}
.slick-slider.slick-dotted .slick-dots{bottom:0;text-align:center;display:block;font-size:0;line-height:0;margin:0;}
.slick-slider.slick-dotted .slick-dots li{margin:0 8px;height:10px;width:10px;}
.slick-slider.slick-dotted .slick-dots li button{padding:0;height:10px;width:10px;font-size:0;line-height:0;border-radius:10px;background-color:#CCCCCC;}
.slick-slider.slick-dotted .slick-dots li button:after,
.slick-slider.slick-dotted .slick-dots li button:before{content:none;display:none;}
.slick-slider.slick-dotted .slick-dots li.slick-active button{background-color:#F58634;}
.main-banner-section{display:block;font-size:0;line-height:0;margin:0;padding:0;position:relative;z-index:0;}
.main-banner-section .main-banner-section-image{display:block;font-size:0;line-height:0;margin:0;padding:0;position:relative;z-index:1;}
.main-banner-section .main-banner-section-image img{width:100%;max-width:100%;height:auto;z-index:0;}
.main-banner-section .main-banner-section-image:before{content:"";position:absolute;left:0;top:0;width:70%;height:100%;background:rgb(0,51,115);background:linear-gradient(120deg, rgba(0,51,115,1) 0%, rgba(0,51,115,0.66) 45%, rgba(0,51,115,0) 70%);filter:progid:DXImageTransform.Microsoft.gradient(startColorstr="#003373",endColorstr="#003373",GradientType=1);}
.main-banner-section .main-banner-section-image:after{content:"";position:absolute;left:0;bottom:0;width:100%;height:35%;background:rgb(255,255,255);background:linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 50%);filter:progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#ffffff",GradientType=1);}
.main-banner-section .main-banner-section-title-button{position:absolute;left:0;top:60%;height:auto;z-index:4;width:100%;-webkit-transform:translate(0,-50%);transform:translate(0,-50%);}
.main-banner-section .main-banner-section-title-button .main-banner-section-title{padding:0 0 48px 0;text-align:left;}
.main-banner-section .main-banner-section-title-button .main-banner-section-button{text-align:left;}
.main-banner-section .main-banner-section-title-button .main-banner-section-title h1{color:#fff;}
.main{position:relative;z-index:0;}
header{position:absolute;z-index:1;width:100%;top:0;}
/*Classic Properties*/
.page-header-with-back-image-title-subtitle{background-position:center center;background-attachment:scroll;background-repeat:no-repeat;background-size:cover;padding:160px 0 107px 0;display:block;position:relative;z-index:0;}
.page-header-with-back-image-title-subtitle:after{content:"";position:absolute;z-index:1;left:0;top:0;width:100%;height:100%;background-color:#00000075;}
.page-header-with-back-image-title-subtitle .container{position:relative;z-index:3;}
.page-header-with-back-image-title-subtitle-inner{text-align:left;}
.page-header-with-back-image-title-subtitle-inner h1{color:#fff;margin:0 0 20px 0;}
.page-header-with-back-image-title-subtitle-inner h3{color:#fff;text-transform:uppercase;font-family:'Montserrat', sans-serif;font-weight:400;font-style:normal;line-height:45px;}
.page-header-with-back-image-title-subtitle-inner h3 span{color:#F58634;}
.page-header-with-back-image-title-subtitle-inner h3 sub{bottom:3px;font-size:42.5%;}
.mfp-wrap .mfp-image-holder .mfp-close, .mfp-wrap .mfp-iframe-holder .mfp-close{color:#FFF;right:-6px;text-align:right;padding-right:6px;width:100%;height:20px;line-height:20px;top:5px;opacity:1;}
.mfp-wrap img.mfp-img{padding:30px 0 20px;}
.mfp-wrap .mfp-bottom-bar{margin-top:-18px;position:absolute;top:100%;left:0;width:100%;cursor:auto;}
.mfp-wrap .mfp-title{display:none;}
.mfp-wrap .mfp-counter{position:absolute;top:0;right:0;color:#fff;font-size:12px;line-height:18px;white-space:nowrap;}
.single-properties-gallery-outer{padding:70px 0 40px 0;background-color:#CCCCCC;}
.single-properties-gallery-outer .single-properties-gallery-single{margin-bottom:30px;border-radius:10px;overflow:hidden;font-size:0;line-height:0;}
.single-properties-gallery-outer .single-properties-gallery-single a{display:block;border-radius:10px;overflow:hidden;font-size:0;line-height:0;}
.single-properties-gallery-outer .single-properties-gallery-single a img{display:block;font-size:0;line-height:0;width:100%;max-width:100%;}
.container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl{padding-right:15px;padding-left:15px;}
.row{margin-top:0;margin-left:-15px;margin-right:-15px;}
.row>*{margin-top:0;padding-right:15px;padding-left:15px;}
.single-property-description{padding:70px 0 35px 0;}
.single-property-description .single-property-description-title{font-size:0;line-height:0;display:block;position:relative;margin:0;padding:0 0 35px 0;}
.single-property-description .single-property-description-title .single-property-description-title-inner{text-align:center;}
.single-property-description .single-property-description-title .single-property-description-title-inner h4{line-height:47px;text-align:center;}
.single-property-description-content{}
.single-property-description-content .single-property-description-content-inner{}
.single-property-amenities{padding:35px 0 35px 0;}
.single-property-amenities .single-property-amenities-title{font-size:0;line-height:0;display:block;position:relative;margin:0;padding:0 0 35px 0;}
.single-property-amenities .single-property-amenities-title .single-property-amenities-title-inner{text-align:center;}
.single-property-amenities .single-property-amenities-title .single-property-amenities-title-inner h4{line-height:47px;text-align:center;}
.single-property-amenities-content-inner{}
.single-property-amenities-content-inner .single-property-amenities-content-inner-title{text-align:left;font-size:0;line-height:0;display:block;position:relative;margin:0;padding:0 0 20px 0;}
.single-property-amenities-content-inner .single-property-amenities-content-inner-title h6{text-align:left;font-weight:900;font-style:normal;margin:0;}
.single-property-amenities-content-inner-content{padding:0 0 35px 0;}
.single-property-amenities-content-inner-content ul{list-style-type:none;margin:0;padding:0;font-size:0;line-height:0;clear:both;overflow:hidden;display:block;position:relative;}
.single-property-amenities-content-inner-content ul li{list-style-type:none;margin:0 0 4px 0;padding:0 0 0 27px;font-size:15px;line-height:25px;clear:both;overflow:hidden;display:block;position:relative;}
.single-property-amenities-content-inner-content ul li:before{content:"";position:absolute;left:0;top:4px;width:17px;height:17px;background-image:url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAARCAYAAAA7bUf6AAAABHNCSVQICAgIfAhkiAAAAYdJREFUOE+tVC1Mw1AQvq9lS5aRbAaBIIEQFIa0WJJZMEBImGTTGLCAmGC1GBwhDEcwDAN2QeBYMChIwCEwI1khbGuP917Wbu22bglU9f3cd999370DhT4uLKS/Y/oWgzLMlAGQZuYaQBUwVRJN5xyFx1p3GLoXtmWuicAzGRgG99ZtwHxy76Hs7fkg9aKRA7SzQcE9jNnNj+9XS3JfgUgG4vdqVIDOPV6XjCA1sGP6a1QJUaUlm84Mvixjh0k7GoUFUpOE2SVyq5f+dZC7C7tolglYHQaCiTkayx4T/9SpdbLRVRFfo26ZNRBSActEAH88d7K1AUQyal1sB86UW7a1yAGAKUNldG4PyX26IY9BPwDf4n5M9OUD0uZXyL0/Jc3YFMb1MvD7hvhzoCYeEEkNQiUE9GOhSZQ7mpklfrkTud4H6q7cUX0S19/C4g5zS56zgE82nOn/6Vgv45/fjgekXjFRKao0WYKgn+v7in3L5DyJazlmZORMkYAqUMwSgCuJhlsKz5Nfw9q/bC0kjPgAAAAASUVORK5CYII=);background-repeat:no-repeat;background-position:center center;background-size:contain;}
.single-property-amenities-content-inner-content ul li:last-child,
.single-property-amenities-content-inner-content ul li:last-of-type{margin:0;}
.single-property-amenities-button{display:block;text-align:center;}
.single-property-testimonials{padding:35px 0 70px 0;}
.single-property-testimonials .single-property-testimonials-title{font-size:0;line-height:0;display:block;position:relative;margin:0;padding:0 0 35px 0;}
.single-property-testimonials .single-property-testimonials-title .single-property-testimonials-title-inner{text-align:center;}
.single-property-testimonials .single-property-testimonials-title .single-property-testimonials-title-inner h4{line-height:47px;text-align:center;margin:0 0 5px 0;}
.single-property-testimonials .single-property-testimonials-title .single-property-testimonials-title-inner p{font-size:15px;line-height:19px;text-align:center;margin:0;font-weight:500;}
.single-property-testimonials-content{}
.single-property-testimonials-content .single-property-testimonials-content-inner{padding:0 0 20px 0;}
.single-property-price-content {margin: 80px 0 0;text-align: center;}
.single-property-testimonials-slider-inner{position:relative;z-index:0;margin:0;padding:0;display:flex;flex-wrap:wrap;align-items:center!important;}
.single-property-testimonials-slider-inner .single-property-testimonials-slider-inner-image{flex:0 0 auto;width:196px;max-width:100%;}
.single-property-testimonials-slider-inner .single-property-testimonials-slider-inner-title-info{flex:0 0 auto;max-width:100%;width:100%;background-color:rgba(181,181,181,0.5);border-radius:10px;padding:30px 30px 30px 110px;background-image:url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAAtCAYAAADydghMAAAABHNCSVQICAgIfAhkiAAAAeNJREFUaEPt2L9PwlAQB/BXCIvyBxknEjHUzUl2Y6IlDq5uuLE6iJNxNSbG1f4TOrvp5tSJokbp2UYJpfbHu3vXluFY21fe59u7e0mt9qULqpQf9CeOfUt5dPvC7auGuqGszVsTQsESMFus8oa1o5SS1o4q/0bpYaYg/x4jPaydp/SwdlTSw0sJyNBiqhwZWtggZWhhE8u4X3qYKUjpYWyQ0sPYxKSHfxOQocVUOTK0sEHK0MImVuXQAoAgsNTeu2PfUfe5NnZ3G5a6p65PWwcKXmazVof1M22EDf/swB/Y1yab5QbPsR/HW69sYC5sFBQnOI6Nns0C5sRygpNYFjA3lgsc7uttFrQ2ojKOt5fRGy4DywGOsN8KOp+DnefkLCGDy8KagvOw5JIuE2sCLsKSwGVjqWAdLBW8b3rOFp3R2GNJF4sGgwUn/pF9XrRh0+sYMAaLAleFxZQ0FqsNrhKrC6ZgtcBVY/XA4H0BbKads0XtlHsO14EtBoMXqGZ36mw/FeHSrmeC68Lmg82wmSVdJzYbbI5NBdeNTQfzYP+Bw8+Yp77TG1F6g3PN8jnMh02CzyZOb8i5ceqzFmBebBy8MthFScOVyTTOCjua0sNVebPzTa6PH7rQVN700H6kVknWuh/MgGPD1d/6ggAAAABJRU5ErkJggg==);background-repeat:no-repeat;background-size:auto;background-position:left 30px top 0; }
.single-property-testimonials-slider-inner .single-property-testimonials-slider-inner-title-info .single-property-testimonials-slider-inner-info{padding:0 0 26px 0;font-size:15px;line-height:25px;font-weight:500;min-height:101px;}
.single-property-testimonials-slider-inner .single-property-testimonials-slider-inner-title-info .single-property-testimonials-slider-inner-title{text-align:right;}
.single-property-testimonials-slider-inner .single-property-testimonials-slider-inner-title-info .single-property-testimonials-slider-inner-title h4{text-align:right;}
.r-hex{overflow:hidden;display:block;margin:0;width:11.6em;height:10.118em;-webkit-transform:rotate(0deg) skewX(30deg);transform:rotate(0deg) skewX(30deg);border-radius:0.5em;position:relative;}
.r-hex *, .r-hex *:before{display:block;overflow:hidden;width:inherit; height:inherit;border-radius:inherit;}
.r-hex-inner{-webkit-transform:skewX(-30deg) rotate(60deg) skewX(30deg);transform:skewX(-30deg) rotate(60deg) skewX(30deg);opacity:1;transition:opacity .75s;cursor:pointer;}
.r-hex:first-child .r-hex-inner:before, .r-hex-inner-2{-webkit-transform:skewX(-30deg) rotate(60deg) skewX(30deg);transform:skewX(-30deg) rotate(60deg) skewX(30deg);background:#CCCCCC;content:'';}
.r-hex-inner-2:before{margin-top:0;height:10.118em;-webkit-transform:skewX(-30deg) rotate(-120deg);transform:skewX(-30deg) rotate(-120deg);background-size:cover;background-position:center center;line-height:0;text-align:center;content:'';}
.r-hex-inner-2{position:absolute;top:0;}
.slick-dotted.slick-slider{margin-bottom:0;}
.single-property-testimonials-content-inner  .slick-slider.slick-dotted .slick-dots{bottom:-20px;}

.single-property-related{padding:70px 0 70px 0;background-color:rgba(181,181,181,0.5);}
.single-property-related .single-property-related-title{font-size:0;line-height:0;display:block;position:relative;margin:0;padding:0 0 35px 0;}
.single-property-related .single-property-related-title .single-property-related-title-inner{text-align:center;}
.single-property-related .single-property-related-title .single-property-related-title-inner h4{line-height:47px;text-align:center;margin:0;}
.single-property-related .single-property-related-content{}
.single-property-related-single{border-radius:10px;background-color:#fff;overflow:hidden;}
.single-property-related-single .single-property-related-single-image{border-radius:10px;background-color:#fff;overflow:hidden;font-size:0;line-height:0;margin:0;padding:0;}
.single-property-related-single .single-property-related-single-image a{display:block;font-size:0;line-height:0;margin:0;padding:0;}
.single-property-related-single .single-property-related-single-image a img{display:block;font-size:0;line-height:0;margin:0;padding:0;width:100%;max-width:100%;height:auto;}
.single-property-related-single .single-property-related-single-all-other{border-radius:0 0 10px 10px;overflow:hidden;font-size:0;line-height:0;margin:0;padding:18px 20px 20px 20px;}
.single-property-related-single .single-property-related-single-all-other .single-property-related-single-title{display:block;font-size:0;line-height:0;margin:0;padding:0 0 9px 0;}
.single-property-related-single .single-property-related-single-all-other .single-property-related-single-title h6{font-size:17px;line-height:22px;display:block;color:#000;}
.single-property-related-single .single-property-related-single-all-other .single-property-related-single-title h6 a{display:block;text-decoration:none;color:#000;}
.single-property-related-single .single-property-related-single-all-other .single-property-related-single-title h6 a:hover{color:#F23F11;}
.single-property-related-single .single-property-related-single-all-other .single-property-related-single-rating{display:block;position:relative;vertical-align:middle;text-align:left;}
.single-property-related-single .single-property-related-single-all-other .single-property-related-single-rating .single-property-related-single-rating-stars{display:inline-block;position:relative;vertical-align:middle;font-size:13px;line-height:13px;}
.single-property-related-single .single-property-related-single-all-other .single-property-related-single-rating .single-property-related-single-rating-stars span{font-size:13px;line-height:13px;color:#FFC107;}
.single-property-related-single .single-property-related-single-all-other .single-property-related-single-rating .single-property-related-single-rating-stars .fa-star{color:#000;fill:#000;}
.single-property-related-single .single-property-related-single-all-other .single-property-related-single-rating .single-property-related-single-rating-stars .fa-star.checked{color:#FFC107;fill:#FFC107;}
.single-property-related-single .single-property-related-single-all-other .single-property-related-single-rating .single-property-related-single-rating-reviews{display:inline-block;position:relative;vertical-align:middle;margin:0 0 0 5px;}
.single-property-related-single .single-property-related-single-all-other .single-property-related-single-rating .single-property-related-single-rating-reviews span{font-size:10px;line-height:13px;color:#000;}
.single-property-related-single .single-property-related-single-all-other .single-property-related-single-details{padding:10px 0 20px 0;font-size:0;line-height:0;margin:0;}
.single-property-related-single .single-property-related-single-all-other .single-property-related-single-details ul{list-style-type:none;font-size:0;line-height:0;margin:0;padding:0;display:block;}
.single-property-related-single .single-property-related-single-all-other .single-property-related-single-details ul li{list-style-type:none;font-size:0;line-height:0;margin:0 0 10px 0;padding:0;display:block;}
.single-property-related-single .single-property-related-single-all-other .single-property-related-single-details ul li:last-child,
.single-property-related-single .single-property-related-single-all-other .single-property-related-single-details ul li:last-of-type{margin:0;}
.single-property-related-single .single-property-related-single-all-other .single-property-related-single-details ul li label{font-size:17px;line-height:20px;color:#707070;display:inline-block;position:relative;vertical-align:middle;margin:0 6px 0 0;}
.single-property-related-single .single-property-related-single-all-other .single-property-related-single-details ul li span{display:inline-block;position:relative;vertical-align:middle;font-size:17px;line-height:20px;color:#000;}
.single-property-related-single .single-property-related-single-all-other .single-property-related-single-details ul li.status span{color:#F23F11;}
.single-property-related-single .single-property-related-single-all-other .single-property-related-single-other-details{border-top:1px solid #707070;padding:20px 0 0 0;}
.single-property-related-single .single-property-related-single-all-other .single-property-related-single-other-details .single-property-related-single-link{float:left;font-size:17px;line-height:20px;color:#000;}
.single-property-related-single .single-property-related-single-all-other .single-property-related-single-other-details .single-property-related-single-link a{color:#000;text-decoration:none;}
.single-property-related-single .single-property-related-single-all-other .single-property-related-single-other-details .single-property-related-single-link a:hover{color:#F23F11;}
.single-property-related-single .single-property-related-single-all-other .single-property-related-single-other-details .single-property-related-single-rate{float:right;font-size:17px;line-height:20px;color:#707070;}
.single-property-related-single .single-property-related-single-all-other .single-property-related-single-other-details .single-property-related-single-rate span{color:#F23F11;}
/*About*/
.page-header-with-back-image-title-subtitle-inner.text-center{}
.page-header-with-back-image-title-subtitle-inner.text-center h1{margin:0;}
.page-header-with-back-image-title-subtitle.with-center-border{}
.page-header-with-back-image-title-subtitle.with-center-border:before{content:"";width:1px;height:116px;left:50%;bottom:-58px;-webkit-transform:translate(-50%,0);transform:translate(-50%,0);background-color:#707070;font-size:0;line-height:0;z-index:2;position:absolute;}
.about-company-history{padding:120px 0 35px 0;}
.about-company-history .about-company-history-title{font-size:0;line-height:0;display:block;position:relative;margin:0;padding:0 0 35px 0;}
.about-company-history .about-company-history-title .about-company-history-inner{text-align:center;}
.about-company-history .about-company-history-title .about-company-history-inner h4{line-height:47px;text-align:center;margin:0;}
.about-company-history-content .about-company-history-content-inner{padding:0;line-height:29px;}
.about-company-facts{padding:35px 0 70px 0;}
.about-company-facts .about-company-facts-title{font-size:0;line-height:0;display:block;position:relative;margin:0;padding:0 0 35px 0;}
.about-company-facts .about-company-facts-title .about-company-facts-inner{text-align:center;}
.about-company-facts .about-company-facts-title .about-company-facts-inner h4{line-height:47px;text-align:center;margin:0;}
.about-company-facts-content .about-company-facts-content-inner{display:block;position:relative;vertical-align:middle;font-size:0;line-height:0;text-align:center;}
.about-company-facts-content .about-company-facts-content-inner .about-company-facts-single{display:inline-block;position:relative;vertical-align:middle;font-size:0;line-height:0;text-align:center;margin:0 15px;padding:0;}
.about-company-facts-content .about-company-facts-content-inner .about-company-facts-single h4{text-align:center;display:block;margin:0 0 15px 0;}
.about-company-facts-content .about-company-facts-content-inner .about-company-facts-single p{font-size:17px;line-height:20px;color:#707070;}
/*Contact*/
.page-header-with-back-image-title-subtitle.back-bottom-center{background-position:bottom center;}
.page-header-with-back-image-title-subtitle.even-padding{padding:170px 0 170px 0;}
.contact-form-contact-info{padding:70px 0;}
.contact-form-contact-info > .container > .row{margin-left:0;margin-right:0;}
.contact-form-contact-info > .container > .row > .col-12{padding-right:0;padding-left:0;}
.contact-form{border:1px solid #707070;border-right:none;padding:30px 30px 50px 30px;}
.contact-form-title{padding:0 0 49px 0;text-align:left;background-image:url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAABHNCSVQICAgIfAhkiAAAAX5JREFUOE+llAtRA0EQRIkDcHASQAHngDggOAgODgXEAcEBEg4FIOFQAA6gX2o6DHObIgVbNbXfeds991mcfLcPDV/T3MOtBlNMztUvG2dYP2V9kTY/y7yRd3Bpn3sMsBPmSoGD56Q2048GbpSFnTGye/UvituidQbE/3uxPEQSUBS+KVBLcH5dynWGCyyz+aS4LEBUoYgHxb4Lzxp7K8UUUBRSjiVAEtjEimtKEgEIJez7YtaHgAKmAbxQbAFQbBKvC5DXA7sE446EctGUgI+cA8gtHHxIQM6hHJvsUV8UoPJecRPg4O0UsrYysGcSh+hpnmO5U+CE3pcwp3Hh6D4DM8RQrA4K6nunmALiLsNYGyuwBWUNVcCsqirzBU3gIWgWV5X9CkQRT57a2H622WtCOXhwuTUVGkYCY5INtTK/7BU6A2aYbwcG1F8DUFrr7A9g64DtZIXZYs3ZA3nXqFmrLqVMs2mGbvwt8wX8BWa6obu/DXZQyOf1n8bva/0FrhF7dSsJHAYAAAAASUVORK5CYII=);background-position:right 10px top 10px;background-repeat:no-repeat;background-size:auto;}
.contact-form-title h4{text-align:left;}
.contact-form-field-row{padding:0 0 25px 0;}
.contact-form-field-row.submit{padding:30px 0 0 0;}
.contact-form-field-row .contact-form-field{display:block;width:100%;}
.contact-form-field-row .contact-form-field input[type="text"],
.contact-form-field-row .contact-form-field input[type="email"],
.contact-form-field-row .contact-form-field input[type="tel"]{margin:0;padding:10px 0;border:none;border-bottom:1px solid #707070;float:none;display:block;width:100%;font-size:17px;line-height:20px;color:#000;outline:none;}
.contact-form-field-row .contact-form-field textarea:focus,
.contact-form-field-row .contact-form-field input[type="text"]:focus,
.contact-form-field-row .contact-form-field input[type="email"]:focus,
.contact-form-field-row .contact-form-field input[type="tel"]:focus{border:none;border-bottom:1px solid #707070;outline:none;}
.contact-form-field-row .contact-form-field textarea{margin:0;padding:10px 0 0 0;border:none;border-bottom:1px solid #707070;float:none;display:block;width:100%;font-size:17px;line-height:20px;color:#000;height:95px;resize:none;overflow:auto;}
.contact-form-field.submit_input{text-align:center;}
.contact-info{border:1px solid #707070;border-left:none;padding:30px 30px 30px 30px;height:100%;background-color:#0C97B9;}
.contact-info-title{padding:0 0 22px 0;}
.contact-info-contact-details{padding:0 0 30px 0;}
.contact-info-contact-details ul{list-style-type:none;margin:0;padding:0;float:none;clear:both;display:block;text-align:right;font-size:0;line-height:0;}
.contact-info-contact-details ul li{list-style-type:none;margin:0 0 40px 0;padding:0 0 0 40px;float:none;clear:both;display:block;font-size:12px;line-height:20px;color:#fff;position:relative;vertical-align:middle;text-align:left;background-repeat:no-repeat;background-size:auto;}
.contact-info-contact-details ul li:last-child{margin:0;}
.contact-info-contact-details ul li a{font-size:17px;line-height:21px;color:#fff;text-decoration:none;outline:none;box-shadow:none;text-shadow:none;}
.contact-info-contact-details ul li a:hover{color:#000;text-decoration:none;outline:none;box-shadow:none;text-shadow:none;}
.contact-info-contact-details ul li.address{background-image:url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAUCAYAAABSx2cSAAAABHNCSVQICAgIfAhkiAAAAVZJREFUOE+Nk2uRwkAQhA8HJyESkBAHnAMigXMQByBhUXA4IDjgHEQCEuhva5oaNvnBVE3tY6Yfu5tsvpYxaGun7JX/yq3yqrwoz7l9kxad5n/Km7Io76kGAaQQ/ihnagYDnKKQQa0vSHAAyWwwAJgNhGyvZEQFu1VNAUFhBAyIjUMUWTM/BQACrwERjBNgbIxKVGm0rUc0MnzTrPR5ERsB00SRgGQO5oSt0yH6cEQ8AMPYx0YJIHtt0IOyj1cvDKUuOsdYQ9LGoA0cWrmCUaFgkk/OjFgBbBtmhIi9YyL8DUU7ol7PjBXftK3CDAkjjgAxOpjXdyYoTjGmntUppD3kBvsd2czv26Lf+vKPwTmw6TtYk/VXV+8ng1ljnQI33gZ2bbnWWrBt0cQlOvgci7JXvo7Vgmmm0W89a96FI76uTLhQzkrYxwGK3MMbcM12cvpysFB00xPkZVsF6JexOAAAAABJRU5ErkJggg==);background-position:left 3px top 0px;}
.contact-info-contact-details ul li.email{background-image:url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAABHNCSVQICAgIfAhkiAAAAX5JREFUOE+llAtRA0EQRIkDcHASQAHngDggOAgODgXEAcEBEg4FIOFQAA6gX2o6DHObIgVbNbXfeds991mcfLcPDV/T3MOtBlNMztUvG2dYP2V9kTY/y7yRd3Bpn3sMsBPmSoGD56Q2048GbpSFnTGye/UvituidQbE/3uxPEQSUBS+KVBLcH5dynWGCyyz+aS4LEBUoYgHxb4Lzxp7K8UUUBRSjiVAEtjEimtKEgEIJez7YtaHgAKmAbxQbAFQbBKvC5DXA7sE446EctGUgI+cA8gtHHxIQM6hHJvsUV8UoPJecRPg4O0UsrYysGcSh+hpnmO5U+CE3pcwp3Hh6D4DM8RQrA4K6nunmALiLsNYGyuwBWUNVcCsqirzBU3gIWgWV5X9CkQRT57a2H622WtCOXhwuTUVGkYCY5INtTK/7BU6A2aYbwcG1F8DUFrr7A9g64DtZIXZYs3ZA3nXqFmrLqVMs2mGbvwt8wX8BWa6obu/DXZQyOf1n8bva/0FrhF7dSsJHAYAAAAASUVORK5CYII=);background-position:left top;}
.contact-info-contact-details ul li.tel{background-image:url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAABHNCSVQICAgIfAhkiAAAAWBJREFUOE+dlA1RAzEQRqkCqALqACQcDooCrg7AwTmAKiASigIOBVAF1AE4gO/d7ML2Jj22ZOabTa7Ztz9JOjv5HUtN7215J7sJv6WnM9vZyj5Ija172VuppEm2ESAQssG+2fdL2X9BAQLpKiU6lFYATw2AX5KXPnai7IWVnwZ+mhM2jjMtnqW1VFI0y4z+oejksK2+t1kY+ygVhxvpKjh2mjemY3gDkGx2EoeAZfiBLDQft2IygB8GGeEcy+NenkvXx6ToQM8SoL8QvvXSq7TKQuN14b4Vy9TLnIIS/EJ6ssBDzPH9o0w2xQMCStbcV8onGIHpM7aTfp5p7UJT5nulTIKRFS2YS43B955pDThVJs60Bng8fYe2h56cl/lhmWauDtDNIeDQYMuEjCiVVkyNFBAAvSrWO/54dxUqMN796q8M3ZcWcJIIR079xeBU8CgRrGSBEQwAkfmpgTvZnk3fDbZQdXv9hmEAAAAASUVORK5CYII=);background-position:left top;}
.contact-info-social{padding:0 0 30px 0;}
.contact-info-social ul{list-style-type:none;margin:0;padding:0;float:none;clear:both;display:block;text-align:left;font-size:0;line-height:0;}
.contact-info-social ul li{list-style-type:none;margin:0;padding:0 10px;float:none;clear:both;display:inline-block;font-size:0;line-height:0;position:relative;vertical-align:middle;text-align:center;}
.contact-info-social ul li:last-child{padding-right:0;}
.contact-info-social ul li:first-child{padding-left:0;}
.contact-info-social ul li a{display:block;text-decoration:none;outline:none;box-shadow:none;text-shadow:none;}
.contact-info-social ul li a:hover{opacity:0.75;text-decoration:none;outline:none;box-shadow:none;text-shadow:none;}
.contact-info-social ul li a img{display:block;}
.contact-info-image{margin:0;padding:0;float:none;clear:both;display:block;text-align:right;font-size:0;line-height:0;}
.contact-info-image img{display:block;margin:0 auto;width:auto;max-width:100%;height:auto;}
.contact-info-title h4{color:#fff;}
/*Our Properties*/
.our-properties-listing{padding:70px 0;}
.our-properties-listing .our-properties-listing-single{background-repeat:no-repeat;background-size:52vw;margin-bottom:70px;}
.our-properties-listing .our-properties-listing-single.odd{background-position: right 0 top 0;}
.our-properties-listing .our-properties-listing-single.even{background-position: left 0 top 0;}
.our-properties-listing .our-properties-listing-single .column_two{}
.our-properties-listing .our-properties-listing-single .column_two .our-properties-listing-single-image{display:none;margin:0;padding:0;float:none;clear:both;text-align:left;font-size:0;line-height:0;}
.our-properties-listing .our-properties-listing-single .column_two .our-properties-listing-single-image img{display:none;margin:0;padding:0;float:none;clear:both;text-align:left;font-size:0;line-height:0;}
.our-properties-listing .our-properties-listing-single.even .column_one{order:2;}
.our-properties-listing .our-properties-listing-single.even .column_two{order:1;}

.our-properties-listing > div.our-properties-listing-single:last-child{margin-bottom:50px;}
.our-properties-listing-bottom-button{text-align:center;}
.our-properties-listing .our-properties-listing-single.odd .our-properties-listing-single-all-details{width:100%;padding:0 0 59px 40%;position:relative;}
.our-properties-listing .our-properties-listing-single.even .our-properties-listing-single-all-details{width:100%;padding:0 40% 59px 0;position:relative;}

.our-properties-listing-single .our-properties-listing-single-all-details{}
.our-properties-listing-single-all-details .our-properties-listing-single-category-count{position: absolute;width: 74px;height: auto;border: 1px solid #707070;top: 0;padding:30px 10px 20px 10px;
text-align: center;}
.our-properties-listing-single-all-details .our-properties-listing-single-category-count label{-webkit-writing-mode: vertical-rl;-ms-writing-mode: tb-rl;writing-mode: vertical-rl;-webkit-transform: rotate(180deg);transform: rotate(180deg);font-size:10px;line-height:14px;padding:60px 0 0 0;}
.our-properties-listing-single.odd .our-properties-listing-single-all-details .our-properties-listing-single-category-count{left:0;}
.our-properties-listing-single.even .our-properties-listing-single-all-details .our-properties-listing-single-category-count{right:0;}
.our-properties-listing-single-rating-reviews{display:block;position:relative;vertical-align:middle;text-align:left;font-size:0;line-height:0;}
.our-properties-listing .our-properties-listing-single.odd .our-properties-listing-single-rating-reviews{text-align:right;}
.our-properties-listing-single-rating-reviews .single-property-related-single-rating-stars{display:inline-block;position:relative;vertical-align:middle;font-size:13px;line-height:13px;}
.our-properties-listing-single-rating-reviews .single-property-related-single-rating-stars span{font-size:13px;line-height:13px;color:#FFC107;}
.our-properties-listing-single-rating-reviews .single-property-related-single-rating-stars .fa-star{color:#000;fill:#000;}
.our-properties-listing-single-rating-reviews .single-property-related-single-rating-stars .fa-star.checked{color:#FFC107;fill:#FFC107;}
.our-properties-listing-single-rating-reviews .single-property-related-single-rating-reviews{display:inline-block;position:relative;vertical-align:middle;margin:0 0 0 9px;font-size:0;line-height:0;}
.our-properties-listing-single-rating-reviews .single-property-related-single-rating-reviews span{font-size:10px;line-height:13px;color:#000;}
.our-properties-listing-single-details{padding:20px 0 20px 0;font-size:0;line-height:0;margin:0;}
.our-properties-listing-single-details ul{list-style-type:none;font-size:0;line-height:0;margin:0;padding:0;display:block;}
.our-properties-listing-single-details ul li{list-style-type:none;font-size:0;line-height:0;margin:0 0 10px 0;padding:0;display:block;}
.our-properties-listing-single-details ul li:last-child,
.our-properties-listing-single-details ul li:last-of-type{margin:0;}
.our-properties-listing-single-details ul li label{font-size:17px;line-height:20px;color:#707070;display:inline-block;position:relative;vertical-align:middle;margin:0 6px 0 0;}
.our-properties-listing-single-details ul li span{display:inline-block;position:relative;vertical-align:middle;font-size:17px;line-height:20px;color:#000;}
.our-properties-listing-single-details ul li.status span{color:#F23F11;}

.our-properties-listing-single-title{padding:16px 0 0 0;text-align:left;}
.our-properties-listing-single-title h4{display:block;text-align:left;}
.our-properties-listing-single-title h4 a{display:block;text-align:left;}
.our-properties-listing .our-properties-listing-single.odd .our-properties-listing-single-title,
.our-properties-listing .our-properties-listing-single.odd .our-properties-listing-single-title h4,
.our-properties-listing .our-properties-listing-single.odd .our-properties-listing-single-title h4 a{text-align:right;}

.our-properties-listing-single-pricing{padding:16px 0 0 0;}
.our-properties-listing-single-pricing h6{color:#000;font-family:'Montserrat', sans-serif;font-weight:400;font-style:normal;line-height:43px;font-size:18px;text-align:left;}
.our-properties-listing .our-properties-listing-single.odd .our-properties-listing-single-pricing h6{text-align:right;}
.our-properties-listing-single-pricing h6 i{color:#F23F11;font-size:230%;font-style:normal;}
.our-properties-listing-single-short-content{padding:20px 0 0 0;font-size:12px;line-height:18px;text-align:center;}
.our-properties-listing-single-link{text-align:left;}
.our-properties-listing-single-link a{display:inline-block;font-size:12px;line-height:18px;position:relative;color:#707070;padding:0 12px 0 0;}
.our-properties-listing-single-link a:after{content:"";position:absolute;right:0;top:50%;-webkit-transform:translate(0,-50%);transform:translate(0,-50%);height:0;width:0;border-top:5px solid transparent;border-bottom:5px solid transparent;border-left:6px solid #707070;}
.our-properties-listing-single-link a:hover{color:#F23F11;}
.our-properties-listing-single-link a:hover:after{border-left-color:#F23F11;}


.slick-dots li.slick-active button:before {opacity: 1;background: #F58634;}
.slick-dots li button:before {width: 10px;height: 10px;content: '';background: #000;border-radius: 50px;}
.main_second_section_content_slide_inner_image img {height: 200px !important;width: 100%;}
.react-fancybox .thumbnail img{max-width: 100%;}
.wilddunesgallery {padding: 70px 0;}
.react-fancybox .box .image-box img {max-height: 80vh !important;}
.wilddunesallery_grid {width: 20%;display: inline-block}
.wilddunesallery_grid .thumbnail img {height: 150px;object-fit: cover;cursor: pointer;border-radius: 5px;width: 100%;padding: 5px 10px !important;}
.wilddunesvideo {padding: 70px 0;background: #CCCCCC;}
.single-property-video {padding: 50px 0;background: #cccccc;}

/*************************************************
responsive
***************************************************/
@media only screen and (min-width:1169px){
.single-property-related .single-property-related-content > .container{max-width:1128px;}
}
@media only screen and (max-width:1849px){

}
@media only screen and (max-width:1727px){
}
@media only screen and (max-width:1599px){
}
@media only screen and (max-width:1400px){
.our-properties-listing .our-properties-listing-single.odd .our-properties-listing-single-all-details{width:100%;padding:0 0 40px 30%;position:relative;}
.our-properties-listing .our-properties-listing-single.even .our-properties-listing-single-all-details{width:100%;padding:0 30% 40px 0;position:relative;}
.our-properties-listing .our-properties-listing-single{background-size:54vw;}
}
@media only screen and (max-width:1380px){
}
@media only screen and (min-width:1200px){
.container{max-width:1170px;}
}
@media only screen and (min-width:1380px){
.container{max-width:1250px;}	
}
@media only screen and (max-width:1379px){
h1{font-size:63px;line-height:73px;}
.main-banner-section .main-banner-section-title-button .main-banner-section-title{padding:0 0 38px 0;}
.main-third-section .third-section-inner .third-section-inner-text{font-size:16px;line-height:24px;}
.footer-widget-container .footer-widget-column .footer-widget-wrapper.footer-widget-text .footer-widget-content ul li{font-size:11px;line-height:19px;}
.footer-widget-container .footer-widget-column .footer-widget-wrapper .footer-widget-text-inner{font-size:11px;line-height:16px;}
}
@media only screen and (max-width:1199px){
.site-main-menu ul.site-main-menu-ul li{padding:0 15px;}
.site-main-menu ul.site-main-menu-ul > li > a > span{font-size:14px;line-height:18px;}
h1{font-size:51px;line-height:55px;}
.main-banner-section .main-banner-section-title-button .main-banner-section-title{padding:0 0 28px 0;}
.main_second_section_content_slide_inner .main_second_section_content_slide_inner_title_info{padding:10px;}
.main_second_section_content_slide_inner .main_second_section_content_slide_inner_title_info .main_second_section_content_slide_inner_title h5{font-size:19px;line-height:25px;}
.main_second_section_content_slide_inner .main_second_section_content_slide_inner_title_info .main_second_section_content_slide_inner_info{font-size:13px;line-height:17px;}
.main-third-section .third-section-inner .third-section-inner-title h5{font-size:22px;line-height:27px;}
.main-third-section .third-section-inner .third-section-inner-text{font-size:12px;line-height:17px;}
.main-third-section .third-section-inner .third-section-inner-title{padding-bottom:12px;}
.main-third-section .third-section-inner{padding:30px 15px;}
.main-fourth-section-inner-left-single .main-fourth-section-inner-left-single-title h5{font-size:17px;line-height:23px;}
.main_sixth_section_slider_inner img{height:32px;}
.footer-widget-container .footer-widget-column .footer-widget-wrapper.footer-widget-text .footer-widget-content ul li a,
.footer-widget-container .footer-widget-column .footer-widget-wrapper.footer-widget-text .footer-widget-content ul li{font-size:9px;line-height:20px;}
.main-fourth-section-inner-right .fourth-section-right-text{font-size:14px;line-height:20px;}

.single-property-related-single .single-property-related-single-all-other .single-property-related-single-title h6,
.single-property-related-single .single-property-related-single-all-other .single-property-related-single-details ul li label,
.single-property-related-single .single-property-related-single-all-other .single-property-related-single-details ul li span,
.single-property-related-single .single-property-related-single-all-other .single-property-related-single-other-details .single-property-related-single-link,
.single-property-related-single .single-property-related-single-all-other .single-property-related-single-other-details .single-property-related-single-rate{font-size:16px;line-height:20px;}

}
@media only screen and (max-width:1169px){
}
@media only screen and (max-width:1099px){
}
@media only screen and (min-width:992px){
}
@media only screen and (max-width:991px){
.main-banner-section .main-banner-section-title-button .main-banner-section-title{padding:0 0 18px 0;}
h1{font-size:36px;line-height:40px;}
.main-banner-section .main-banner-section-title-button{top:50%;}
.main-third-section .third-section-inner .third-section-inner-title h5{font-size:24px;line-height:29px;}
.main-third-section .third-section-inner .third-section-inner-text{font-size:11px;line-height:16px;min-height:54px;}
.main-third-section .third-section-inner .third-section-inner-image img{height:90px;}
.column_1.footer-widget-column{margin-bottom:35px;}
.column_2.footer-widget-column{margin-bottom:35px;}
.footer-widget-container .footer-widget-column .footer-widget-wrapper.footer-widget-text .footer-widget-content ul li a, .footer-widget-container .footer-widget-column .footer-widget-wrapper.footer-widget-text .footer-widget-content ul li{font-size:14px;line-height:20px;}
.footer-widget-container .footer-widget-column .footer-widget-wrapper .footer-widget-text-inner{font-size:14px;line-height:20px;}
.site-main-menu ul.site-main-menu-ul > li > a > span{font-size:10px;line-height:15px;}
.page-header-with-back-image-title-subtitle{padding:110px 0 57px 0;}
.page-header-with-back-image-title-subtitle-inner h3{font-size:22px;line-height:35px;}
.page-header-with-back-image-title-subtitle-inner h1{margin-bottom:10px;}
.site-main-menu ul.site-main-menu-ul li{padding:0 10px;}
.single-property-amenities-content-inner .single-property-amenities-content-inner-title h6{font-size:18px;line-height:28px;}
.single-property-amenities-content-inner-content ul li{font-size:13px;line-height:23px;}
.single-property-amenities-content-inner-content ul li:before{top:3px;}
.single-property-testimonials-slider-inner .single-property-testimonials-slider-inner-title-info{padding:30px 30px 30px 110px;background-position:left 40px top 0;}
.single-property-testimonials-slider-inner .single-property-testimonials-slider-inner-title-info .single-property-testimonials-slider-inner-info{font-size:13px;line-height:21px;padding:0 0 20px 0;}
.single-property-testimonials-slider-inner .single-property-testimonials-slider-inner-title-info .single-property-testimonials-slider-inner-title h4{font-size:28px;line-height:34px;}
.single-property-related-single{margin-bottom:30px;}
.single-property-related{padding:70px 0 40px 0;}
.contact-form{border-right:1px solid #707070;border-bottom:none;}
.contact-info{border-left:1px solid #707070;border-top:none;}
.page-header-with-back-image-title-subtitle.even-padding{padding:100px 0 100px 0;}
.our-properties-listing .our-properties-listing-single{background-image:none !important;background-position:center center;background:none !important;}
.our-properties-listing .our-properties-listing-single .column_two{order:1;}
.our-properties-listing .our-properties-listing-single .column_one{order:2;}
.our-properties-listing .our-properties-listing-single .column_two .our-properties-listing-single-image{display:block;margin-bottom:35px;}
.our-properties-listing .our-properties-listing-single .column_two .our-properties-listing-single-image img{display:block;}
.our-properties-listing .our-properties-listing-single{margin-bottom:60px;}
}
@media only screen and (min-width:767px) and (max-width:991px){
}
@media only screen and (max-width:767px){
.main-third-section .third-section-inner{margin-bottom:30px;padding:50px 15px;}
.main-third-section .third-section-inner .third-section-inner-text{font-size:15px;line-height:21px;min-height:1px;}
.main-fourth-section-inner-left .main-fourth-section-inner-left-single-column.right{padding-top:0;}
.column_3.footer-widget-column{margin-bottom:35px;}
.footer-widget-container .footer-widget-column .footer-widget-wrapper .footer-widget-title{padding-bottom:18px;}
footer .footer-copyright-container .footer-copyright{margin-bottom:15px;}
footer .footer-copyright-container .footer-social{text-align:left;}
footer .footer-copyright-container .footer-social ul{float:none;text-align:left;}
footer .footer-copyright-container .footer-social ul li:first-child{padding-left:0;}
footer .footer-copyright-container .footer-social ul li:last-child{padding-right:0;}
.main-fifth-section .main-fifth-section-form .subscribe_blog_form .subscribe_blog_form_inn{padding:15px 40px 15px 40px;background-position:left 20px top 30px;}
.main-fifth-section .main-fifth-section-form .subscribe_blog_form .subscribe_blog_form_inn .subscribe_blog_for_input{float:none;width:100%;display:block;}
.subscribe_blog_for_btn{text-align:center;}
.main-fifth-section .main-fifth-section-form .subscribe_blog_form .subscribe_blog_form_inn .subscribe_blog_for_input input.emailadd-input{text-align:center;}
.site-main-menu ul.site-main-menu-ul{display:none;position:absolute;left:0;top:100%;width:100%;margin:0;padding:30px 20px 20px 20px;background-color:#fff;}
.main-banner-section .main-banner-section-image img{height:370px;width:auto;max-width:none;max-width:initial;object-fit:cover;}
.main-banner-section .main-banner-section-image{overflow:hidden;}
h5{font-size:20px;line-height:30px;}
h4{font-size:30px;line-height:35px;}
.fusion-mobile-menu-icons{display:block;}
.fusion-mobile-menu-icons a.fusion-mobile-menu-icons-bars{font-size:22px;line-height:22px;color:#fff;}
header .container .row{position:static;position:initial;}
header .container .row .col-6{position:static;position:initial;}
header .container{position:static;position:initial;}
.site-main-menu ul.site-main-menu-ul.open_mobile_slider{display:block;}
.site-main-menu ul.site-main-menu-ul li{display:block;padding:0px;margin:0 0 10px 0;text-align:left;}
.site-main-menu ul.site-main-menu-ul > li > a{}
.site-main-menu ul.site-main-menu-ul > li > a > span{color:#000;}
.site-main-menu ul.site-main-menu-ul > li > span.fusion-caret{padding-top:0;color:#000;}
.main-banner-section .main-banner-section-title-button{top:60%;}
.main-second-section{padding:30px 0 30px 0;}
.main-third-section{padding:20px 0 30px 0;}
.main-fourth-section{padding:20px 0 20px 0;}
.main-fifth-section{padding:30px 0 30px 0;}
.main-sixth-section{padding:20px 0 40px 0;}
.main-banner-section .main-banner-section-image:before{background:linear-gradient(108deg, rgba(0,51,115,1) 0%, rgba(0,51,115,0.66) 45%, rgba(0,51,115,0) 70%);}
.btn{padding:10px 23px 11px 23px;font-size:14px;line-height:19px;}
.site-main-menu ul.site-main-menu-ul li ul{display:none;position:relative;left:auto;left:initial;top:auto;top:initial;width:100%;height:auto;padding:0;border-top:none;border-left:none;background-color:transparent;}
.site-main-menu ul.site-main-menu-ul > li > a > span{font-size:17px;line-height:20px;}
.site-main-menu ul.site-main-menu-ul > li > ul > li{margin:0;}
.site-main-menu ul.site-main-menu-ul > li > ul > li > a{padding:5px 0;}
.site-main-menu ul.site-main-menu-ul > li > ul > li > span.fusion-caret{-webkit-transform:rotate(0deg);transform:rotate(0deg);top:1px;}
.site-main-menu ul.site-main-menu-ul > li > ul > li{border-right:none;border-bottom:none;}
.site-main-menu ul.site-main-menu-ul > li > ul > li > ul{display:none;position:relative;left:auto;left:initial;top:auto;top:initial;width:100%;height:auto;padding:0;border-top:none;border-left:none;background-color:transparent;}
.site-main-menu ul.site-main-menu-ul > li > ul > li:hover > ul{display:block;}
.site-main-menu ul.site-main-menu-ul > li > ul > li > ul > li{border-right:none;border-bottom:none;margin:0;}
.site-main-menu ul.site-main-menu-ul > li > ul > li > ul > li{padding:0 0 0 15px;}
.site-main-menu ul.site-main-menu-ul > li > ul > li > ul > li:last-child{padding-right:0;}
.site-main-menu ul.site-main-menu-ul > li > ul > li > ul > li > a > span{font-size:15px;line-height:18px;}
.site-main-menu ul.site-main-menu-ul > li > ul > li > ul > li > a > span{padding:5px 0;}
.single-properties-gallery-outer{padding:50px 0 20px 0;}
.single-property-description .single-property-description-title{padding-bottom:25px;}
.single-property-description{padding:50px 0 25px 0;}
.single-property-amenities{padding:25px 0 25px 0;}
.about-company-history .about-company-history-title,
.about-company-facts .about-company-facts-title,
.single-property-testimonials .single-property-testimonials-title,
.single-property-related .single-property-related-title,
.single-property-amenities .single-property-amenities-title{padding-bottom:25px;}
.about-company-facts .about-company-facts-title .about-company-facts-inner h4,
.about-company-history .about-company-history-title .about-company-history-inner h4,
.single-property-related .single-property-related-title .single-property-related-title-inner h4,
.single-property-testimonials .single-property-testimonials-title .single-property-testimonials-title-inner h4,
.single-property-description .single-property-description-title .single-property-description-title-inner h4,
.single-property-amenities .single-property-amenities-title .single-property-amenities-title-inner h4{font-size:30px;line-height:38px;}
.about-company-history-content .about-company-history-content-inner,
.single-property-description-content .single-property-description-content-inner{font-size:15px;line-height:21px;}
.about-company-facts,
.single-property-testimonials{padding:25px 0 50px 0;}
.single-property-testimonials-slider-inner{display:block;flex-wrap:nowrap;}
.single-property-testimonials-slider-inner .single-property-testimonials-slider-inner-image{display:block;margin:0 auto 15px auto;}
.single-property-testimonials-slider-inner .single-property-testimonials-slider-inner-title-info{padding:55px 20px 20px 20px;background-position:left 20px top 0;margin-left:0;width:100%;}
.single-property-related{padding:50px 0 20px 0;}
.single-property-amenities-content-inner-content ul li{margin-bottom:2px;}
.single-property-amenities-content-inner .single-property-amenities-content-inner-title{padding-bottom:10px;}
.single-property-amenities-content-inner .single-property-amenities-content-inner-title h6{font-size:18px;line-height:24px;}
.single-property-amenities-content-inner-content{padding-bottom:25px;}
.single-property-testimonials .single-property-testimonials-title .single-property-testimonials-title-inner h4{margin-bottom:2px;}
.about-company-history{padding:80px 0 25px 0;}
.page-header-with-back-image-title-subtitle.with-center-border:before{height:76px;bottom:-38px;}
.about-company-facts-content .about-company-facts-content-inner .about-company-facts-single{margin:0 10px;}
.about-company-history-content .about-company-history-content-inner{padding:0;}
.contact-form-field.phone,
.contact-form-field.first_name{padding-bottom:25px;}
.contact-form{padding:20px 20px 50px 20px;}
.contact-form-title{padding-bottom:19px;}
.contact-form-field-row.submit{padding:10px 0 0 0;}
.contact-info{padding:20px;}
.our-properties-listing .our-properties-listing-single.odd .our-properties-listing-single-all-details{width:100%;padding:0 0 0px 50px;position:relative;}
.our-properties-listing .our-properties-listing-single.even .our-properties-listing-single-all-details{width:100%;padding:0 50px 0px 0;position:relative;}
.our-properties-listing-single-all-details .our-properties-listing-single-category-count{padding:15px 5px 10px 5px;width:40px;}
.our-properties-listing-single-all-details .our-properties-listing-single-category-count label{padding:20px 0 0 0;}
.our-properties-listing .our-properties-listing-single{margin-bottom:50px;}
.our-properties-listing-single-all-details .our-properties-listing-single-category-count p{font-size:14px;}
.wilddunesallery_grid {width: 33.33%;display: inline-block;}
.wilddunesallery_grid .thumbnail img {height: 100px;padding: 1px 7px !important;}
}
@media (min-width:576px){
}


p.error_msg {font-size: small;color: #ff0000;}
.footer-copyright a {color: #000;}
.main-fourth-section-inner-left-single-column img {height: 250px;object-fit: cover;}
.left{text-align: left;}
.iframe{width: 100%;height: 1250px;}
